import { Cancel, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";
import PublishIcon from "@mui/icons-material/Publish";
import { Alert, Autocomplete, Button, Checkbox, FormControl, styled, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import DELETE_ICON from "../../../../../assets/images/delete-rules-icon.png";
import fetchClientCode from "../../../../../async/client/fetchClientCode";
import { updateInformationById } from "../../../../../redux/actions/SuperAdmin/informationActions";
import ModalPublishedInfo from "../PublishedInformation/ModalPublishedInfo";
import Styles from './EditInformation.module.css';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 5,
      width: "100%",
    },
    "& .MuiSelect-select": {
      fontSize: 16,
    },
  },
});

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      border: '1px solid #e0e0e0',
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #d3c9c9",
    borderColor: "#d3c9c9",
    borderRadius: 8,
    //   width: "100%",
  },
});

export default function EditInformationForm({ information }) {
  const token = localStorage.getItem("accessToken");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const dataInfo = location.state.data;
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalDraft, setModalDraft] = useState(false);
  const [alert, setAlert] = useState(false);
  const [editedData, setEditedData] = useState({
    title: dataInfo.title,
    content: dataInfo.content,
    clientCode: dataInfo.clientCode,
    image: null,
    isPublish: dataInfo.isPublish,
  });
  const inputFileRef = useRef()
  const [preview, setPreview] = useState(dataInfo.image !== null ? dataInfo.image : null);
  const [previewName, setPreviewName] = useState(dataInfo.title + '.jpg');

  const [dataClientCode, setDataClientCode] = useState([]);
  const [selectedClients, setSelectedClients] = useState([])

  useEffect(() => {
    if (modalSuccess || modalDraft) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [modalSuccess, modalDraft])


  const fixedClients = useMemo(() => {
    let pre = [{ codes: "ALL", name: "ALL" }, { codes: 'Tes', name: 'Client Tester' }]
    return pre.concat(dataClientCode);
  }, [dataClientCode])

  const preClients = useMemo(() => {
    const temp = fixedClients.filter((el) => {
      return dataInfo?.clientCode.some((f) => {
        return f === el.codes
      });
    });
    return temp
  }, [fixedClients, dataInfo])

  useEffect(() => {
    setSelectedClients(preClients)
  }, [preClients])


  const handleSelectedClient = (e, value) => {
    e.preventDefault()

    const isExist = selectedClients.find((item) => item?.name === value?.name);
    const doesContainAll = selectedClients.some((item) => item?.codes === 'All');
    let isAll = Boolean(value?.codes === 'All' && value?.name === 'All')
    const isEmpty = selectedClients.length === 0;


    if (!isExist && !isAll && !doesContainAll) {
      let temp = selectedClients.concat(value)
      setSelectedClients(temp)
    } else if (isExist) {
      const x = selectedClients.filter((item) => item !== value);
      setSelectedClients(x)
    } else if (isEmpty && isAll) {
      let temp = selectedClients.concat(value)
      setSelectedClients(temp)
    } else if (!isExist && doesContainAll) {
      setSelectedClients(selectedClients)
    }

  };

  const handleDeleteClient = (value) => {
    const x = selectedClients.filter((item) => item !== value);
    setSelectedClients(x)
  }


  const isSelected = (option) => selectedClients?.indexOf(option) !== -1;



  const handleEditData = (e) => {
    setEditedData((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "file" ? e.target.files : e.target.value
    }));
    if (e.target.type === "file") {
      let url = URL.createObjectURL(e.target.files[0]);
      setPreview(url);
      setPreviewName(e.target.files[0].name);
    }
  };

  const handleDeleteImage = (e) => {
    inputFileRef.current.value = null;
    setPreview(null);
    setPreviewName('');
    setEditedData((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["image"]: null
    }));
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("title", editedData?.title);
    formData.set("content", editedData?.content);
    for (let i = 0; i < selectedClients.length; i++) {
      formData.append("clientCode", selectedClients[i]?.codes);
    }
    if (editedData?.image !== null && editedData?.image !== undefined && editedData?.image !== "") {
      formData.set("image", editedData?.image[0], editedData?.image[0].name);
    }
    formData.set("isPublish", true);

    if (formData.title === "" || formData.content === "") {
      setAlert(true);
    } else {
      dispatch(updateInformationById(token, dataInfo._id, formData));
      setModalSuccess(true);
    }
  };

  const saveToDraft = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.set("title", editedData?.title);
    formData.set("content", editedData?.content);
    for (let i = 0; i < selectedClients.length; i++) {
      formData.append("clientCode", selectedClients[i]?.codes);
    }
    if (editedData?.image !== null && editedData?.image !== undefined && editedData?.image !== "") {
      formData.set("image", editedData.image[0], editedData.image[0].name);
    }
    formData.set("isPublish", false);
    if (formData.title === "" || formData.content === "") {
      setAlert(true);
    } else {
      dispatch(updateInformationById(token, dataInfo._id, formData));
      setModalDraft(true)
    }
  };


  const handleClickCancel = (e) => {
    e.preventDefault();
    history.push("/superadmin/information");
  };

  const [files, setFiles] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
    setEditedData((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["image"]: acceptedFiles
    }));

    setPreview(URL.createObjectURL(acceptedFiles[0]));
    setPreviewName(acceptedFiles[0].name);
  }, []);

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
    multiple: false
  });

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response)
      } catch (error) {
        console.log()
      }
    }
    )()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const capitalizedClientName = (clientName) => {
    return clientName?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Pt', 'PT')
      .replace('Cv', 'CV')
  }

  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <form onSubmit={handleSubmit}>
          <div className={Styles.WrapperBox} style={{ paddingInline: 0 }}>
            {alert && <Alert sx={{ marginBottom: 3 }} severity="warning">Title and Content are mandatories!</Alert>}
            <div style={{ display: "flex", gap: 10 }}>
              <div className={Styles.ItemBox}>
                <span>To</span>
                <FormControl size="small" fullWidth>
                  <Autocomplete
                    disablePortal
                    value={null}
                    blurOnSelect={true}
                    // value={selectedClient}
                    disableCloseOnSelect
                    options={fixedClients}
                    getOptionLabel={(option) => option?.name}
                    sx={{ width: '100%' }}
                    onChange={(e, value) =>
                      value === null
                        ? handleSelectedClient(e, null)
                        : handleSelectedClient(e, value)
                    }
                    renderOption={(props, option) => {
                      const isItemSelected = isSelected(option)
                      return (
                        <li {...props} onClick={(e) => e.preventDefault()} key={option?.codes} style={{ borderBottom: '1px solid #c2c2c2', padding: '12px' }}>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}>
                            <span className={Styles.ClientOption}>{capitalizedClientName(option?.name)}</span>
                            <Checkbox
                              onClick={(e) => handleSelectedClient(e, option)}
                              sx={{ margin: 0, padding: 0 }}
                              checked={isItemSelected}
                              icon={<RadioButtonUnchecked sx={{ color: '#c2c2c2' }} />}
                              checkedIcon={<RadioButtonChecked sx={{ color: '#1571DE' }} />} />
                          </div>
                        </li>

                      );
                    }}
                    renderInput={(params) => <MuiTextField {...params} size="small" sx={{ background: 'var(--main-bg)' }} placeholder="Select company" />}
                  />
                </FormControl>
              </div>
              <div className={Styles.ItemBox}>
                <span className={Styles.ItemBoxSpan}>Title</span>
                <input
                  className={Styles.ItemBoxInput}
                  type="text"
                  name="title"
                  value={editedData?.title}
                  onChange={handleEditData}
                  placeholder="Type title here"
                />
              </div>
            </div>
            {selectedClients?.length > 0 && (
              <div className={Styles.ClientsChipContainer}>
                {selectedClients.map((client, i) => (
                  <div key={i} className={Styles.ClientsChip}>
                    <span>
                      {capitalizedClientName(client?.name)}
                    </span>
                    <Cancel className={Styles.CancelIcon} onClick={() => handleDeleteClient(client)} />
                  </div>
                ))}

              </div>
            )}
            <div className={Styles.ItemBoxContent}>
              <span>Content</span>
              <textarea type="text" name="content" value={editedData.content || ""} onChange={handleEditData} />
            </div>
            <div className={Styles.ContainerUploadImage}>
              <div {...getRootProps()} className={Styles.WrapperUploadImage} >
                <input {...getInputProps()} onChange={handleEditData} name="image" type="file" ref={inputFileRef} hidden />


                {preview ? (
                  <div
                    style={{
                      width: '100%',
                      margin: 0,
                      padding: 0,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                      gap: 30
                    }}
                  >
                    <img
                      src={preview}
                      style={{
                        width: "245px",
                        height: "158px",
                        objectFit: "cover",
                        borderRadius: 8
                      }}
                      alt={preview}
                      onClick={() => inputFileRef.current.click()}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 20
                        }}>
                        <span style={{ textAlign: 'start' }}>{previewName}</span>
                        <Button
                          type="button"
                          style={{
                            border: "1px solid #1571DE",
                            color: "#1571DE",
                            width: 120,
                            height: 40,
                            padding: 10,
                            borderRadius: 8
                          }}
                          onClick={() => inputFileRef.current.click()}
                        >
                          Change File
                        </Button>
                      </span>
                      <img src={DELETE_ICON} onClick={handleDeleteImage} alt="delete" style={{ width: '18px', height: '20px', cursor: 'pointer' }} />

                    </div>
                  </div>


                ) : (
                  <div className={Styles.BoxInputImage}>
                    <span>Drag & Drop File Here</span>
                    <div className={Styles.BoxInputFile}>
                      <span>File must JPG or PNG</span>
                    </div>
                    <div className={Styles.BoxInputOr}>
                      <span> OR </span>
                    </div>
                    <div className={Styles.ContainerInputImage}>{ }</div>
                    <div className={Styles.BoxInputButton}>
                      <Button onClick={() => inputFileRef.current.click()}>
                        <span>Browse File</span>
                        <PublishIcon />
                      </Button>
                    </div>
                  </div>
                )}

              </div>
            </div>

          </div>
          <div className={Styles.ButtonHelper} style={{ paddingInline: 0 }}>
            <Button
              style={{
                border: "1px solid #1571DE",
                color: "#1571DE",
                width: 100,
                height: 40,
                padding: 10,
                borderRadius: 8
              }}
              onClick={handleClickCancel}
            >
              Cancel
            </Button>
            <Button
              style={{
                background: "#1571DE",
                color: "white",
                width: 100,
                height: 40,
                padding: 10,
                borderRadius: 8
              }}
              type="submit"
            // onClick={() => setModalSuccess(true)}
            >
              Publish
            </Button>
            <span style={{ cursor: 'pointer' }} onClick={saveToDraft}>Save to Draft</span>
          </div>
        </form>
        {modalSuccess && (
          <ModalPublishedInfo
            setModalSuccess={setModalSuccess}
            history={history}
            title="Successfully Published"
            text="you have send one information to the client"
          />
        )}
        {modalDraft && (
          <ModalPublishedInfo
            setModalSuccess={setModalDraft}
            history={history}
            title="Saved to Draft"
            text="you have saved one information to draft"
          />
        )}
      </div>
    </div>
  );
}
