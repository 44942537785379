import axios from "axios";
import {API} from "../../api";
// import { FETCH_ALL_EMPLOYEE, FETCH_ALL_EMPLOYEE_BEGIN, FETCH_ALL_EMPLOYEE_BY_ID } from "../../type";
import * as types from "../../typeExternal";
import { setSnackbar } from "../SuperAdmin/snackbarActions";

// FETCH ALL EMPLOYEES
export const fetchAllEmployeesExternal = (token, query) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_EMPLOYEE_EXTERNAL_BEGIN });
    axios
      .get(`${API}/user/list/client?${query}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_EMPLOYEE_EXTERNAL_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.FETCH_EMPLOYEE_EXTERNAL_ERROR,
          payload: err.response,
        });
      });
  };
};

// FETCH ALL EMPLOYEES
export const fetchAllEmployeesSupervisor = (token, query) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_EMPLOYEE_EXTERNAL_BEGIN });
    axios
      .get(`${API}/user/spv/list?${query}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_EMPLOYEE_EXTERNAL_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: types.FETCH_EMPLOYEE_EXTERNAL_ERROR,
          payload: err.response,
        });
      });
  };
};

// FETCH EMPLOYEE BY ID
export const fetchEmployeeExternalById = (token, employeeId, query) => {
  return (dispatch) => {
    axios
      .get(`${API}/user/admin/employee/${employeeId}?${query}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_EMPLOYEE_EXTERNAL_BY_ID,
          payload: res.data,
        });
        // console.log(res.data.data, 'from actions');
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.error,
          })
        );
      });
  };
};

export const updateSPVorAPVExt = (token, id, body, query) => {
  return (dispatch) => {
    axios
      .patch(`${API}/contract/admin/${id}`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_EMPLOYEE_BY_ID,
          payload: res.data.response,
        });
        return Promise.resolve();
      })
      .then(() => {
        dispatch(fetchAllEmployeesExternal(token, id, query))
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.error,
          })
        );
        return Promise.reject(err);
      });
  };
}

// UPDATE EMPLOYEE BY ID
export const updateEmployeeById = (token, id, body, groupName) => {
  return (dispatch) => {
    axios
      .patch(`${API}/auth/edit/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_EMPLOYEE_BY_ID,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Employee updated successfully",
          })
        );
        return Promise.resolve();
      })
      .then(() => {
        dispatch(fetchAllEmployeesExternal(token, groupName));
      })
      .catch((err) => {
        // console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        return Promise.reject(err);
      });
  };
};

// UPDATE EMPLOYEE WORK TIME AND SPV
export const updateWorkTimeAndSpv = (token, id, body, groupName) => {
  return (dispatch) => {
    axios
      .patch(`${API}/auth/update/${id}`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_EMPLOYEE_BY_ID,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Employee updated successfully",
          })
        );
        return Promise.resolve();
      })
      .then(() => {
        dispatch(fetchAllEmployeesExternal(token, groupName));
      })
      .catch((err) => {
        // console.log(err);
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "error",
            snackbarMessage: err.response.data.message,
          })
        );
        return Promise.reject(err);
      });
  };
};

// DELETE EMPLOYEE BY ID
export const deleteEmployeeByIdExternal = (token, id, groupName) => {
  return (dispatch) => {
    axios
      .delete(`${API}/auth/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({
          type: types.DELETE_EMPLOYEE_BY_ID,
          payload: res.data.data,
        });
      })
      .then(() => {
        dispatch(
          setSnackbar({
            snackbarOpen: true,
            snackbarType: "success",
            snackbarMessage: "Employee deleted successfully",
          })
        );
      })
      .then(() => {
        dispatch(fetchAllEmployeesExternal(token, groupName));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
