/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Grid, Container, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppDetailInformationSA } from "../../../../components/SuperAdmin/_information";
import Breadcrumb from "../../../../components/utils/Breadcrumbs";
import DELETE_ICON from "../../../../assets/images/delete-rules-icon.png";
import EDIT_ICON from "../../../../assets/images/create.png";
import { useHistory, useLocation, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ModalDeleteInformation from "../../../../components/SuperAdmin/_information/InformationTable/DeleteInformation/ModalDeleteInformation";
import { fetchInformationById } from "../../../../redux/actions/SuperAdmin/informationActions";
export default function DetailInformation() {
   const token = localStorage.getItem('accessToken');
  const location = useLocation()
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {informationById} = useSelector((state) => state.informations);
  const information = useSelector((state) => location?.state?.data)
  const [selectedInformation, setSelectedInformation] = useState({}); //


  useEffect(()=> {
    dispatch(fetchInformationById(token, id))
  }, [token, dispatch, id])


  return (
    <Container maxWidth="xl">
      <div className="container-client-settings">
        <Helmet>
          <title>MyWorkSpace | Detail Information</title>
        </Helmet>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Breadcrumb>
            <Button
              onClick={() => history.push("/superadmin/information")}
              style={{ textTransform: "none" }}
            >
              <span style={{ fontSize: 20, color: "#255bfc" }}>Information</span>
            </Button>
            <span style={{ fontSize: 20, color: "#255bfc" }}>Detail Information</span>
          </Breadcrumb>
          <div className="item-edit-delete-settings" style={{display: 'flex', gap: 10}}>
            <Button
              sx={{ "&:hover": { backgroundColor: 'transparent' }, padding: 0 }}
              onClick={() => {
                history.push({
                  pathname: `/superadmin/edit-information/${id}`,
                  state: {
                    data: information,
                  },
                });
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 6,
                cursor: 'pointer',
              }}>
              <span style={{
                fontSize: 14,
                color: "#1571DE",
                textTransform: 'capitalize'
              }}>Edit</span>
              <img src={EDIT_ICON} alt="edit" style={{ height: '17px', width: '15px' }} />
            </Button>
            <Button
              sx={{ "&:hover": { backgroundColor: 'transparent' }, padding: 0 }}
              onClick={(event) => {
                event.preventDefault();
                setSelectedInformation(information);
              }}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 6,
                cursor: 'pointer',
                marginRight: 8
              }}
            >
              <span style={{
                fontSize: 14,
                color: "#D1421A",
                textTransform: 'capitalize'
              }}>Delete</span>
              <img src={DELETE_ICON} alt="delete" style={{ height: '17px', width: '15px' }} />
            </Button>

          </div>
        </div>
        {selectedInformation._id && (
          <ModalDeleteInformation
            active={selectedInformation}
            onOpen={setSelectedInformation}
            description="Are you sure you want to delete this information?"
            label="Delete Information"
            pathName="/superadmin/information"
          />
        )}

        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppDetailInformationSA data={informationById} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}
