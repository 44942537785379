import moment from "moment";
import info_default_banner from "../../../../../src/assets/images/info_default_banner.jpg";
import Styles from "./DetailInformationSA.module.css";
import { Business } from "@mui/icons-material";
import { Switch } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import fetchClientCode from "../../../../async/client/fetchClientCode";
import ChangePublishModal from "./ChangePublishModal/ChangePublishModal";
import ModalPublishedInfoDetails from "./PublishedInformation/ModalPublishedInfo";
import { useHistory } from "react-router-dom";
import { API_IMAGE } from "../../../../redux/api";

export default function DetailInformationSA({ data }) {
  const token = localStorage.getItem("accessToken");
  const history = useHistory();
  const [dataClientCodes, setDataClientCodes] = useState([]);
  const [changePublish, setChangePublish] = useState(false)
  const [active, setActive] = useState(null)

  const [modalPublish, setModalPublish] = useState(false)

  const fixedClients = useMemo(() => {
    let pre = [{ codes: "ALL", name: "ALL" }, { codes: 'Tes', name: 'Client Tester' }]
    return pre.concat(dataClientCodes);
  }, [dataClientCodes])

  const dataClients = useMemo(() => {
    return fixedClients.filter(el => {
      return data?.clientCode?.find(element => {
        return element === el?.codes
      })
    })
  }, [data, fixedClients])



  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        // setDataClientCode(response?.map((el) => el?.name));
        setDataClientCodes(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePublish = (e) => {
    setChangePublish(true)
    setActive(data)
  }

  const capitalizedClientName = (clientName) => {
    return clientName?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Pt', 'PT')
      .replace('Cv', 'CV')
  }

  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <div className={Styles.StatusHeader}>
          <div className={Styles.StatusDesc}>
            <span>Status Information</span>
            <span>Change the information status to publish or unpublish</span>
          </div>
          <div className={Styles.StatusSwitcher}>
            <Switch
              checked={data?.isPublish || false}
              size="large"
              onChange={handlePublish}
            />
          </div>
        </div>
        <div className={Styles.ClientsLabelContainer}>
          {dataClients.map((each, i) => (
            <div key={i} className={Styles.ClientsLabel}>
              <Business className={Styles.ClientIcon} />
              <span>{capitalizedClientName(each?.name)}</span>
            </div>
          ))}
        </div>
        <div className={Styles.Banner}>
          <img src={data?.image?.replace(`https://myworkspace.id/api/image/`, `${API_IMAGE}`) || info_default_banner} alt="banner" />
        </div>
        <div className={Styles.ArticleHeader}>
          <span>{data?.title}</span>
          <span>{moment(data?.createdAt).format("LLL")}</span>
        </div>
        <div className={Styles.Content}>
          <span>{data?.content}</span>
        </div>
      </div>
      {changePublish && (
        <ChangePublishModal setModalPublish={setModalPublish} setChangePublish={setChangePublish} active={active} />
      )}
      {modalPublish && (
        <ModalPublishedInfoDetails
        setModalPublish={setModalPublish}
        history={history}
        title="Success Published"
            text="you have send one information to the client"
        id={data?._id}
        />
      )}
    </div>
  );
}
