import React, { useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Helmet } from "react-helmet";
import { AppInformationSA } from '../../../components/SuperAdmin/_information'
import { useDispatch, useSelector } from "react-redux";
import { fetchAllInformation } from "../../../redux/actions/SuperAdmin/informationActions";

export default function InformationSA() {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const { allInformation, loadingDataInformation } = useSelector((state) => state.informations)

  // useEffect(() => {
  //   dispatch(fetchAllInformation(token));
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[])
  useEffect(() => {
    dispatch(fetchAllInformation(token));
  },[dispatch, token])
  return (
    <Container maxWidth="xl">
      <Helmet>
        <title>MyWorkSpace | Information</title>
      </Helmet>
      <div className="wrapper-client-SA">
        <h2
          className="page-header"
          style={{ fontWeight: 500, color: "#0A0A0A", fontSize: 40 }}
        >
          Information
        </h2>
      </div>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <AppInformationSA informations={allInformation} loading={loadingDataInformation} />
        </Grid>
      </Grid>
    </Container>
  );
}
