// USER LOGIN REGISTER
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const SET_USERNAME = "SET_USERNAME";
export const LOADING_TOGGLE_ACTION = "LOADING_TOGGLE_ACTION";
export const SET_USERNAME_FAILED = "SET_USERNAME_FAILED";

export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const LOADING_DATA = "LOADING_DATA";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// ATTENDANCES
export const FETCH_ALL_ATTENDANCES_BEGIN = "FETCH_ALL_ATTENDANCES_BEGIN";
export const FETCH_ALL_ATTENDANCES_SUCCESS = "FETCH_ALL_ATTENDANCES_SUCCESS";
export const FETCH_ALL_ATTENDANCES_ERROR = "FETCH_ALL_ATTENDANCES_ERROR";

export const FETCH_SCHEMA_ATTENDANCE_BY_ID = "FETCH_SCHEMA_ATTENDANCE_BY_ID";
export const UPDATE_ATTENDANCES = "UPDATE_ATTENDANCES";
export const CREATE_ATTENDANCES = "CREATE_ATTENDANCES";
export const DELETE_ATTENDANCES = "DELETE_ATTENDANCES";
// export const FETCH_DATA_ATTENDANCE_REPORT = "FETCH_DATA_ATTENDANCE_REPORT";

// CLIENT
export const FETCH_ALL_CLIENTS_BEGIN = "FETCH_ALL_CLIENTS_BEGIN";
export const FETCH_ALL_CLIENTS_SUCCESS = "FETCH_ALL_CLIENTS_SUCCESS";
export const FETCH_ALL_CLIENTS_ERROR = "FETCH_ALL_CLIENTS_ERROR"

export const SET_LOAN_SCHEME = "SET_LOAN_SCHEME";
export const SET_ATTENDANCE_SCHEME = "SET_ATTENDANCE_SCHEME";

export const FETCH_CLIENT_SET_BEGIN = "FETCH_CLIENT_SET_BEGIN";
export const FETCH_CLIENT_SET_SUCCESS = "FETCH_CLIENT_SET_SUCCESS";
export const FETCH_CLIENT_SET_ERROR = "FETCH_CLIENT_SET_ERROR";

export const FETCH_CLIENT_NOT_SET_BEGIN = "FETCH_CLIENT_NOT_SET_BEGIN";
export const FETCH_CLIENT_NOT_SET_SUCCESS = "FETCH_CLIENT_NOT_SET_SUCCESS";
export const FETCH_CLIENT_NOT_SET_ERROR = "FETCH_CLIENT_NOT_SET_ERROR";

export const ADD_CLIENT = "ADD_CLIENT";
export const EDIT_CLIENT_BEGIN = "EDIT_CLIENT_BEGIN";
export const EDIT_CLIENT_SUCCESS = "EDIT_CLIENT_SUCCESS";
export const EDIT_CLIENT_ERROR = "EDIT_CLIENT_ERROR";
export const DELETE_CLIENT = "DELETE_CLIENT";
export const SET_SCHEMA_ATTENDANCES = "SET_SCHEMA_ATTENDANCES";
export const SET_ACTIVE_CLIENT = "SET_ACTIVE_CLIENT";
export const SET_CLIENT_ATTENDANCES = "SET_CLIENT_ATTENDANCES";
export const FETCH_CLIENT_BY_ID_BEGIN = "FETCH_CLIENT_BY_ID_BEGIN";
export const FETCH_CLIENT_BY_ID_SUCCESS = "FETCH_CLIENT_BY_ID_SUCCESS";
export const FETCH_CLIENT_BY_ID_ERROR = "FETCH_CLIENT_BY_ID_ERROR";

// USER ADMIN
export const FETCH_ALL_USERS_BEGIN = "FETCH_ALL_USERS_BEGIN";
export const FETCH_ALL_USERS_SUCCESS = "FETCH_ALL_USERS_SUCCESS";
export const FETCH_ALL_USERS_ERROR = "FETCH_ALL_USERS_ERROR";
export const REGISTER_USER_ADMIN_SUCCESS = "REGISTER_USER_ADMIN_SUCCESS";
export const REGISTER_USER_ADMIN_ERROR = "REGISTER_USER_ADMIN_ERROR";
export const DELETE_USER_ADMIN = "DELETE_USER_ADMIN";
export const UPDATE_USER_ADMIN = "UPDATE_USER_ADMIN";
export const GET_USER_ADMIN_BY_ID = "GET_USER_ADMIN_BY_ID";

// FAQ
export const FETCH_ALL_FAQS = "FETCH_ALL_FAQS";
export const FETCH_FAQS_BY_ID = "FETCH_FAQS_BY_ID";
export const CREATE_FAQ = "CREATE_FAQ";
export const UPDATE_FAQ = "UPDATE_FAQ";
export const DELETE_FAQ = "DELETE_FAQ";

export const FETCH_ALL_FAQS_BEGIN = "FETCH_ALL_FAQS_BEGIN";
export const FETCH_ALL_FAQS_SUCCESS = "FETCH_ALL_FAQS_SUCCESS";
export const FETCH_ALL_FAQS_ERROR = "FETCH_ALL_FAQS_ERROR";

// DRAFT
export const FETCH_ALL_DRAFT = "FETCH_ALL_DRAFT";
export const FETCH_DRAFT_BY_ID = "FETCH_DRAFT_BY_ID";
export const CREATE_DRAFT = "CREATE_DRAFT";
export const UPDATE_DRAFT = "UPDATE_DRAFT";
export const DELETE_DRAFT = "DELETE_DRAFT";

export const FETCH_ALL_DRAFT_BEGIN = "FETCH_ALL_DRAFT_BEGIN";
export const FETCH_ALL_DRAFT_SUCCESS = "FETCH_ALL_DRAFT_SUCCESS";
export const FETCH_ALL_DRAFT_ERROR = "FETCH_ALL_DRAFT_ERROR";

// DATA EMPLOYEE
export const FETCH_ALL_DATA_EMPLOYEE = "FETCH_ALL_DATA_EMPLOYEE";
export const FETCH_DATA_EMPLOYEE_BY_ID = "FETCH_DATA_EMPLOYEE_BY_ID";
export const FETCH_ALL_DATA_EMPLOYEE_BY_RANGE ="FETCH_ALL_DATA_EMPLOYEE_BY_RANGE";
export const FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_DATE_ID ="FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_DATE_ID";

export const FETCH_ALL_DATA_EMPLOYEE_BEGIN = "FETCH_ALL_DATA_EMPLOYEE_BEGIN";
export const FETCH_ALL_DATA_EMPLOYEE_SUCCESS = "FETCH_ALL_DATA_EMPLOYEE_SUCCESS";
export const FETCH_ALL_DATA_EMPLOYEE_ERROR = "FETCH_ALL_DATA_EMPLOYEE_ERROR";

export const FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_BEGIN ="FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_BEGIN";
export const FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_SUCCESS ="FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_SUCCESS";
export const FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_ERROR ="FETCH_ALL_DATA_EMPLOYEE_BY_RANGE_ERROR";

export const CLEAR_DATA = 'CLEAR_DATA';

export const FETCH_DATA_ATTENDANCE_REPORT_BEGIN = "FETCH_DATA_ATTENDANCE_REPORT_BEGIN";
export const FETCH_DATA_ATTENDANCE_REPORT_SUCCESS = "FETCH_DATA_ATTENDANCE_REPORT_SUCCESS";
export const FETCH_DATA_ATTENDANCE_REPORT_ERROR = "FETCH_DATA_ATTENDANCE_REPORT_ERROR";


// EMPLOYEE
export const FETCH_ALL_EMPLOYEE = "FETCH_ALL_EMPLOYEE";
export const FETCH_ALL_EMPLOYEE_BY_ID = "FETCH_ALL_EMPLOYEE_BY_ID";
export const UPDATE_EMPLOYEE_BY_ID = "UPDATE_EMPLOYEE_BY_ID";
export const DELETE_EMPLOYEE_BY_ID = "DELETE_EMPLOYEE_BY_ID";

export const FETCH_ALL_EMPLOYEE_SUCCESS = "FETCH_ALL_EMPLOYEE_SUCCESS";
export const FETCH_ALL_EMPLOYEE_BEGIN = "FETCH_ALL_EMPLOYEE_BEGIN";
export const FETCH_ALL_EMPLOYEE_ERROR = "FETCH_ALL_EMPLOYEE_ERROR";

// e-PaySlip
export const FETCH_ALL_SLIP_PAYMENT = "FETCH_ALL_SLIP_PAYMENT";
export const FETCH_ALL_SLIP_PAYMENT_BY_ID = "FETCH_ALL_SLIP_PAYMENT_BY_ID";
export const FETCH_SLIP_PAYMENT_BY_ID = "FETCH_SLIP_PAYMENT_BY_ID";
export const FETCH_ALL_PAYSLIP = "FETCH_ALL_PAYSLIP";
export const FETCH_ALL_PAYSLIP_BY_ID = "FETCH_ALL_PAYSLIP_BY_ID";
export const FETCH_ALL_PAYSLIP_BY_YEAR = "FETCH_ALL_PAYSLIP_BY_YEAR";
export const FETCH_ALL_PAYSLIP_BY_MONTH = "FETCH_ALL_PAYSLIP_BY_MONTH";
export const UPLOAD_DATA_PAYSLIP = "UPLOAD_DATA_PAYSLIP";


export const FETCH_ALL_PAYSLIP_BEGIN = "FETCH_ALL_PAYSLIP_BEGIN";
export const FETCH_ALL_PAYSLIP_SUCCESS = "FETCH_ALL_PAYSLIP_SUCCESS";
export const FETCH_ALL_PAYSLIP_ERROR = "FETCH_ALL_PAYSLIP_ERROR";

export const FETCH_ALL_PAYSLIP_BY_MONTH_BEGIN = "FETCH_ALL_PAYSLIP_BY_MONTH_BEGIN";
export const FETCH_ALL_PAYSLIP_BY_MONTH_SUCCESS = "FETCH_ALL_PAYSLIP_BY_MONTH_SUCCESS";
export const FETCH_ALL_PAYSLIP_BY_MONTH_ERROR= "FETCH_ALL_PAYSLIP_BY_MONTH_ERROR";


// LOAN
export const FETCH_ALL_SCHEMA_LOAN_BEGIN = "FETCH_ALL_SCHEMA_LOAN_BEGIN";
export const FETCH_ALL_SCHEMA_LOAN_SUCCESS = "FETCH_ALL_SCHEMA_LOAN_SUCCESS";
export const FETCH_ALL_SCHEMA_LOAN_ERROR = "FETCH_ALL_SCHEMA_LOAN_ERROR";

export const FETCH_SCHEMA_LOAN_BY_ID = "FETCH_SCHEMA_LOAN_BY_ID";
export const FETCH_DATA_LOAN_BY_USER_ID = "FETCH_DATA_LOAN_BY_USER_ID";
export const FETCH_PAYMENT_LOAN_FROM_IOS = "FETCH_PAYMENT_LOAN_FROM_IOS";
export const CREATE_SCHEMA_LOAN = "CREATE_SCHEMA_LOAN";
export const UPDATE_SCHEMA_LOAN = "UPDATE_SCHEMA_LOAN";
export const DELETE_SCHEMA_LOAN = "DELETE_SCHEMA_LOAN";
export const CREATE_TENOR = "CREATE_TENOR";
export const DELETE_TENOR = "DELETE_TENOR";
export const UPDATE_TENOR = "UPDATED_TENOR";
export const APPROVE_LOAN = "APPROVE_LOAN";
export const REJECT_LOAN = "REJECT_LOAN";


export const FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_BEGIN ="FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_BEGIN";
export const FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_SUCCESS ="FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_SUCCESS";
export const FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_ERROR ="FETCH_DATA_ONGOING_LOAN_BY_RANGE_DATE_ERROR";

export const FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_BEGIN = "FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_BEGIN";
export const FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_SUCCESS = "FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_SUCCESS";
export const FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_ERROR = "FETCH_DATA_HISTORY_LOAN_BY_RANGE_DATE_ERROR";

export const FETCH_DATA_LOAN_REPORT_BEGIN = "FETCH_DATA_LOAN_REPORT_BEGIN";
export const FETCH_DATA_LOAN_REPORT_SUCCESS = "FETCH_DATA_LOAN_REPORT_SUCCESS";
export const FETCH_DATA_LOAN_REPORT_ERROR = "FETCH_DATA_LOAN_REPORT_ERROR";


export const FETCH_ALL_ON_GOING_LOAN = "FETCH_ALL_ON_GOING_LOAN";
export const FETCH_ALL_APPROVED_LOAN_HISTORY ="FETCH_ALL_APPROVED_LOAN_HISTORY";

// INFORMATION
export const ADD_INFORMATION = "ADD_INFORMATION";
export const UPDATE_INFORMATION = "UPDATE_INFORMATION";
export const DELETE_INFORMATION = "DELETE_INFORMATION";
export const FETCH_ALL_INFORMATION = "FETCH_ALL_INFORMATION";
export const FETCH_ALL_INFORMATION_BY_ID = "FETCH_ALL_INFORMATION_BY_ID";

export const FETCH_ALL_INFORMATION_BEGIN = "FETCH_ALL_INFORMATION_BEGIN";
export const FETCH_ALL_INFORMATION_SUCCESS = "FETCH_ALL_INFORMATION_SUCCESS";
export const FETCH_ALL_INFORMATION_ERROR = "FETCH_ALL_INFORMATION_ERROR";

// BPJS
export const FETCH_ALL_BPJS_DATA = "FETCH_ALL_BPJS_DATA";
export const FETCH_BPJS_DATA_BY_ID = "FETCH_BPJS_DATA_BY_ID";

export const FETCH_ALL_BPJS_DATA_BEGIN = "FETCH_ALL_BPJS_DATA_BEGIN";
export const FETCH_ALL_BPJS_DATA_SUCCESS = "FETCH_ALL_BPJS_DATA_SUCCESS";
export const FETCH_ALL_BPJS_DATA_ERROR = "FETCH_ALL_BPJS_DATA_ERROR";

// WORKSPACE 
export const FETCH_ALL_WORKSPACE_SCHEMA_BEGIN = "FETCH_ALL_WORKSPACE_SCHEMA_BEGIN";
export const FETCH_ALL_WORKSPACE_SCHEMA_SUCCESS = "FETCH_ALL_WORKSPACE_SCHEMA_SUCCESS";
export const FETCH_ALL_WORKSPACE_SCHEMA_ERROR = "FETCH_ALL_WORKSPACE_SCHEMA_ERROR";

export const GET_WORKSPACE_BY_ID = "GET_WORKSPACE_BY_ID";
export const CREATE_WORKSPACE_SCHEMA = "CREATE_WORKSPACE_SCHEMA";
export const UPDATE_WORKSPACE_BY_ID = "UPDATE_WORKSPACE_BY_ID";
export const DELETE_WORKSPACE_BY_ID = "DELETE_WORKSPACE_BY_ID";

// SETTING
export const FETCH_ALL_SETTING_SCHEMA_BEGIN = "FETCH_ALL_SETTING_SCHEMA_BEGIN";
export const FETCH_ALL_SETTING_SCHEMA_SUCCESS = "FETCH_ALL_SETTING_SCHEMA_SUCCESS";
export const FETCH_ALL_SETTING_SCHEMA_ERROR = "FETCH_ALL_SETTING_SCHEMA_ERROR";

export const GET_SETTING_SCHEMA_BY_ID = "GET_SETTING_SCHEMA_BY_ID";
export const CREATE_SETTING_SCHEMA = "CREATE_SETTING_SCHEMA";
export const UPDATE_SETTING_SCHEMA_BY_ID = "UPDATE_SETTING_SCHEMA_BY_ID";
export const DELETE_SETTING_SCHEMA_BY_ID = "DELETE_SETTING_SCHEMA_BY_ID";

export const UPDATE_STATUS_SETTING = "UPDATE_STATUS_SETTING";

//DATABASE
export const FETCH_ALL_LOCATIONS_BEGIN = "FETCH_ALL_LOCATIONS_BEGIN";
export const FETCH_ALL_LOCATIONS_SUCCESS = "FETCH_ALL_LOCATIONS_SUCCESS";
export const FETCH_ALL_LOCATIONS_ERROR = "FETCH_ALL_LOCATIONS_ERROR";

export const FETCH_ALL_CONTRACTS_BEGIN = "FETCH_ALL_CONTRACTS_BEGIN";
export const FETCH_ALL_CONTRACTS_SUCCESS = "FETCH_ALL_CONTRACTS_SUCCESS";
export const FETCH_ALL_CONTRACTS_ERROR = "FETCH_ALL_CONTRACTS_ERROR";


export const ADD_LOCATION = "ADD_LOCATION";
export const GET_LOCATION_BY_ID = "GET_LOCATION_BY_ID";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const DELETE_LOCATION = "DELETE_LOCATION";
export const SET_EMPLOYEE_LOCATION = "SET_EMPLOYEE_LOCATION";
export const DELETE_USER_FROM_LOCATION = "DELETE_USER_FROM_LOCATION";

export const FETCH_ALL_BANKS_BEGIN = "FETCH_ALL_BANKS_BEGIN";
export const FETCH_ALL_BANKS_SUCCESS = "FETCH_ALL_BANKS_SUCCESS";
export const FETCH_ALL_BANKS_ERROR = "FETCH_ALL_BANKS_ERROR";


export const FETCH_ALL_ACTIVITY_BEGIN = "FETCH_ALL_ACTIVITY_BEGIN";
export const FETCH_ALL_ACTIVITY_SUCCESS = "FETCH_ALL_ACTIVITY_SUCCESS";
export const FETCH_ALL_ACTIVITY_ERROR = "FETCH_ALL_ACTIVITY_ERROR";


export const ADD_BANK = "ADD_BANK";
export const EDIT_BANK = "UPDATE_BANK";
export const DELETE_BANK = "DELETE_BANK";
export const GET_BANK_BY_ID = "GET_BANK_BY_ID";


// INDUCTIONS 
export const ADD_INDUCTION = "ADD_INDUCTION";
export const UPDATE_INDUCTION = "UPDATE_INDUCTION";
export const DELETE_INDUCTION = "DELETE_INDUCTION";
// export const FETCH_ALL_INDUCTION = "FETCH_ALL_INDUCTION";
export const FETCH_INDUCTION_BY_ID = "FETCH_INDUCTION_BY_ID";

export const FETCH_ALL_INDUCTION_BEGIN = "FETCH_ALL_INDUCTION_BEGIN";
export const FETCH_ALL_INDUCTION_SUCCESS = "FETCH_ALL_INDUCTION_SUCCESS";
export const FETCH_ALL_INDUCTION_ERROR = "FETCH_ALL_INDUCTION_ERROR";

