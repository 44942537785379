/* eslint-disable no-unused-vars */


import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Styles from "./InformationTable.module.css";
import AddIcon from "@mui/icons-material/Add";
import {
  Box, Button,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography
} from "@mui/material";
import moment from "moment";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchAllInformation
} from "../../../../redux/actions/SuperAdmin/informationActions";
import { UseCompanyName } from "../../../utils/hooks";
import SearchbarSA from "../../../utils/SearchbarSA/SearchbarSA";
import Scrollbar from "../../_user/TableUsers/Scrollbar";
import ModalDeleteInformation from "./DeleteInformation/ModalDeleteInformation";
import { visuallyHidden } from "@mui/utils";
import LoadingAnimation from "../../../utils/LoadingAnimation/LoadingAnimation";
import CustomTablePagination from "../../../_pagination/CustomTablePagination";


const TABLE_HEAD = [
  { id: "clientCode", label: "Client", alignRight: false },
  { id: "title", label: "Title", alignRight: false },
  { id: "createdAt", numeric: true, label: "Creation Date", alignRight: false },
  { id: "isPublish", numeric: true, label: "Status", alignRight: false },
  { id: "" },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

// eslint-disable-next-line no-unused-vars
function EnhancedTableHead(props) {
  const {
    order,
    orderBy,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      sx={{
        "& tr": {
          backgroundColor: "#F0F0F0",
        },
      }}
    >
      <TableRow>

        {TABLE_HEAD.map((headCell, index) => (
          <TableCell
            key={headCell.id + index}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function InformationTable({ informations, loading }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const getCompany = UseCompanyName();
  const token = localStorage.getItem("accessToken");
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Name");
  const [selectedInfo, setSelectedInfo] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // eslint-disable-next-line no-unused-vars
  const [searchTerm, setSearchTerm] = useState("");
  const [listDataInformation, setListDataInformation] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openedPopover, setOpenedPopover] = React.useState(null);


  var sorted_informations = informations.sort((a, b) => {
    return new Date(a.createdAt).getTime() -
      new Date(b.createdAt).getTime()
  }).reverse();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearch = (event) => {
    setListDataInformation(event.target.value);
  };
  const handleSubmitFilter = (e) => {
    e.preventDefault();
    setSearchTerm(listDataInformation);
  };


  const handleClickAdd = (e) => {
    e.preventDefault();
    history.push("/superadmin/add-information");
  };

  const selectedUser = (userId) => {
    let client;
    if (userId !== 'ALL' && userId !== 'Tes') {
      let temp = getCompany.find((u) => u.code === userId)
      if (temp) {
        client = temp
      } else {
        client = { name: 'Unlisted Client' }
      }
    } else if (userId === 'ALL') {
      client = { name: 'All Clients' }
    } else {
      client = { name: 'Client Tester' }
    }
    return client
  }

  const selectedUsersNames = (clientCodes) => {
    var clients = clientCodes?.map((clientCode) => {
      let user = selectedUser(clientCode)
      return user?.name
    })
    return clients
  }

  const handleClientsPopover = (e, id) => {
    setAnchorEl(e.currentTarget);
    setOpenedPopover(id)
  }

  const handleClose = () => {
    setAnchorEl(null);
    setOpenedPopover(null)
  }

  const capitalizedClientName = (clientName) => {
    return clientName?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Pt', 'PT')
      .replace('Cv', 'CV')
  }

  return (
    <div className={Styles.Container}>
      <div className="wrapper-information">
        <div className={Styles.Header}>
          <div className={Styles.Searchbar}>
            <SearchbarSA
              value={listDataInformation}
              onChange={handleSearch}
              onSubmit={handleSubmitFilter}
              searchWidth="379px"
              placeholder="Search by title"
            />
          </div>
          <div>
            <Button
              startIcon={<AddIcon style={{ fontSize: 16 }} />}
              className={Styles.ButtonAdd}
              onClick={handleClickAdd}
            >
              <span className={Styles.AddText}>Add Information</span>
            </Button>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 50,
            }}
          >
            <div style={{ width: 200 }}>
              <LoadingAnimation />
            </div>
          </div>
        ) : (
          <div className={Styles.TableWrapper}>
            <Scrollbar>
              <TableContainer
                className={Styles.TableContainer}
              >
                <Table
                  id="emp-table"
                  className={Styles.Table}
                >
                  <colgroup>
                    <col style={{ width: '30%%' }} />
                    <col style={{ width: '25%' }} />
                    <col style={{ width: '20%' }} />
                    <col style={{ width: '10%' }} />
                    <col style={{ width: '15%' }} />
                  </colgroup>
                  <EnhancedTableHead
                    numSelected={informations.length}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    values={informations}
                  />
                  <TableBody>
                    {stableSort(sorted_informations, getComparator(order, orderBy))
                      .filter((el) => el.title?.toLowerCase().includes(listDataInformation.toLowerCase()))
                      .slice((page - 1) * rowsPerPage, (page - 1) * rowsPerPage + rowsPerPage)
                      .map((data, ind) => {
                        const client = selectedUser(data?.clientCode[0]);
                        const clients = selectedUsersNames(data?.clientCode)
                        return (
                          <TableRow
                            hover
                            key={'tablebody' + ind}
                            tabIndex={-1}
                          >
                            <TableCell className={Styles.ClientCell}>
                              <span>
                                {capitalizedClientName(client?.name)}
                              </span>
                              {data?.clientCode?.length > 1 && (
                                <span
                                  onMouseEnter={(e) => handleClientsPopover(e, data?._id)}
                                  onMouseLeave={handleClose}
                                   className={Styles.Plus}
                                  >
                                  + {data?.clientCode?.length - 1}
                                </span>
                              )}
                              <Popover
                                id={data?._id}
                                sx={{
                                  pointerEvents: 'none',
                                }}
                                open={openedPopover === data?._id}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                              >
                                <div className={Styles.PopoverDiv}>
                                  {clients?.slice(1)?.map((client, index) => (
                                    <span key={index}>{(index === clients?.length - 2 && clients?.length !== 2) && '& '}{capitalizedClientName(client)}{index !== clients?.length - 2 ? ', ' : '.'} </span>
                                  ))}
                                </div>
                              </Popover>
                            </TableCell>
                            <TableCell>

                              {data?.title
                                ? data?.title
                                : ""}

                            </TableCell>

                            <TableCell>

                              {data?.createdAt
                                ? moment(data?.createdAt).format("LL")
                                : ""}

                            </TableCell>
                            <TableCell>
                              <Typography
                                sx={{
                                  fontSize: 12,
                                  fontWeight: 400,
                                }}
                              >
                                {data?.isPublish
                                  ? <span className={Styles.Published}>Published</span>
                                  : <span className={Styles.Draft}>Draft</span>}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <Link
                                to={{
                                  pathname: data.isPublish ? `/superadmin/detail-information/${data._id}` : `/superadmin/edit-information/${data._id}`,
                                  state: { data: data },
                                }}
                              >
                                <span style={{ color: '#1571DE' }}>Lihat Detail</span>
                              </Link>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={informations.filter(
                (el) => el.title?.toLowerCase().includes(listDataInformation.toLowerCase())
              )
                .length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              style={{
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderTop: 0,
                borderBottomLeftRadius: 8,
                borderBottomRightRadius: 8,
                overflow: 'hidden',
                boxShadow: 'var(--box-shadow)',
              }}
            /> */}
            <CustomTablePagination
              limit={rowsPerPage}
              setLimit={setRowsPerPage}
              page={page}
              setPage={setPage}
              totalPage={Math.ceil(informations.filter(
                (el) => el.title?.toLowerCase().includes(listDataInformation.toLowerCase())
              )
                .length / rowsPerPage)}
            />
          </div>
        )}


        {selectedInfo._id && (
          <ModalDeleteInformation
            active={selectedInfo}
            onOpen={setSelectedInfo}
            description="Are you sure you want to delete this information?"
            label="Delete Information"
            pathName="/superadmin/information"
          />


        )}
      </div>
    </div >

  );
}
