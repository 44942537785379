import React from "react";
import Styles from "./ApprovalModalReject.module.css";
import REJECTED_ICON from "../../../assets/images/rejected-approval.png";
import { Backdrop, Button, Fade, Modal } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import {API} from "../../../redux/api";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  "@media screen and (max-width: 478px)": {
    bottom: 0,
    top: "auto",
    transform: "translate(-50%, 0)",
  },
};

// const modalStyle = {
//   "@media screen and (max-width: 950px)": {
//     display: "none",
//   },
// };

// import { useHistory } from "react-router";

// const clickOutsideRef = (content_ref, toggle_ref) => {
//   document.addEventListener("mousedown", (e) => {
//     if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
//       content_ref.current.classList.toggle("active");
//     } else {
//       if (content_ref.current && !content_ref.current.contains(e.target)) {
//         content_ref.current.classList.remove("active");
//       }
//     }
//   });
// };

export default function ApprovalModalReject(props) {
  const {
    active,
    onOpen,
    type,
    dataAttendance,
    dataEmployee,
    // dataResign,
    dataOvertime,
    onOpenRejection,
  } = props;


  const handleReject = async () => {
    if (type === "attendance" && dataAttendance?.isRevision === false) {
      await axios
        .patch(`${API}/attendance/web-approval/${dataAttendance?._id}`, {
          status: "REJECTED",
        })
        .then((res) => {
          console.log(res);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (type === "attendance" && dataAttendance?.isRevision === true) {
      await axios
        .patch(
          `${API}/attendance/web-revision-approval/${dataAttendance?._id}`,
          { status: "REJECTED" }
        )
        .then((res) => {
          console.log(res);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (type === "employee") {
      await axios
        .post(`${API}/profile/approval/${dataEmployee?.profileChange?._id}`, {
          status: "REJECTED",
        })
        .then((res) => {
          console.log(res);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (type === "resign") {
      onOpen(false);
      onOpenRejection(true);
    } else if (type === "overtime-approval-web") {
      await axios
      .patch(`${API}/attendance/overtime-approval/${dataOvertime?._id}`, {
        status: "REJECTED",
        applyDate: dataOvertime?.applyDate,
        startTime: dataOvertime?.startTime,
        endTime: dataOvertime?.endTime,
      })
      .then((res) => {
        console.log(res);
          window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  };

  return (
    <Modal
      open={active}
      onClose={onOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      // sx={modalStyle}
    >
      <Fade in={active}>
        <Box sx={style}>
          <div className={Styles.ModalDeleteBackground}>
            <div className={Styles.ModalContainerContent}>
              <div className={Styles.WrapperItemModalSuccess}>
                <div className={Styles.Image}>
                  <img src={REJECTED_ICON} alt="rejected-icon" />
                </div>
                <div className={Styles.Content}>
                  <span>Reject</span>
                  <span>Are you sure you want to reject this request?</span>
                </div>
                <div className={Styles.ButtonAction}>
                  <Button onClick={() => onOpen(false)}>Cancel</Button>
                  <Button onClick={handleReject}>Yes</Button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
