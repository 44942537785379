/* eslint-disable no-unused-vars */

import React, { useEffect, useMemo, useState } from "react";
// eslint-disable-next-line no-unused-vars
//import "./AddInformationForm.css";
import PublishIcon from "@mui/icons-material/Publish";
import { Alert, Autocomplete, Button, Checkbox, FormControl, TextField } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { useCallback, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import DELETE_ICON from "../../../../../assets/images/delete-rules-icon.png";
import fetchClientCode from "../../../../../async/client/fetchClientCode";
import { addInformation } from "../../../../../redux/actions/SuperAdmin/informationActions";
import ModalPublishedInfo from "../PublishedInformation/ModalPublishedInfo";
import Styles from "./AddInformationForm.module.css";
import { Cancel, Circle, CircleOutlined, RadioButtonChecked, RadioButtonUnchecked } from "@mui/icons-material";

const useStyles = makeStyles({
  selectBorder: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1.5px solid #d3c9c9",
      borderColor: "#d3c9c9",
      borderRadius: 5,
      width: "100%",
    },
    "& .MuiSelect-select": {
      fontSize: 16,
    },
  },
});

const MuiTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: 8,
      border: '1px solid #e0e0e0',
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1.5px solid #d3c9c9",
    borderColor: "#d3c9c9",
    borderRadius: 8,
    //   width: "100%",
  },
});

export default function AddInformationForm() {
  const token = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const history = useHistory();
  const inputFileRef = useRef()
  const [files, setFiles] = useState([]);
  const [preview, setPreview] = useState(null);
  const [previewName, setPreviewName] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [value, setValue] = useState({
    title: "",
    clientCode: [],
    content: "",
    image: null,
    isPublish: "",
  });
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalDraft, setModalDraft] = useState(false);
  const [alert, setAlert] = useState(false);

  const [selected, setSelected] = useState("All");
  const [selectedCode, setSelectedCode] = useState("All");
  const [dataClientCode, setDataClientCode] = useState([]);

  const [selectedClient, setSelectedClient] = useState({
    name: "", codes: ""
  })
  const [selectedClients, setSelectedClients] = useState([])


  const handleEditData = (e) => {
    setValue((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.type === "file" ? e.target.files : e.target.value,
    }));

    if (e.target.type === "file") {
      let url = URL.createObjectURL(e.target.files[0]);
      setPreview(url);
      setPreviewName(e.target.files[0].name);
    }
  };

  const handleDeleteImage = (e) => {
    inputFileRef.current.value = null;
    setPreview(null);
    setPreviewName("");
    setValue((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["image"]: null
    }));
  };


  const onDrop = useCallback((acceptedFiles) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setValue((prev) => ({
      ...prev,
      // eslint-disable-next-line no-useless-computed-key
      ["image"]: acceptedFiles
    }));

    setPreview(URL.createObjectURL(acceptedFiles[0]));
    setPreviewName(acceptedFiles[0].name);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    multiple: false,
  });


  const fixedClients = useMemo(() => {
    let pre = [{ codes: "ALL", name: "ALL" }, { codes: 'Tes', name: 'Client Tester' }]
    return pre.concat(dataClientCode);
  }, [dataClientCode])

  const handleSelectedClient = (e, value) => {
    e.preventDefault()
    setSelected(value);

    const isExist = selectedClients.find((item) => item?.name === value?.name);
    const doesContainAll = selectedClients.some((item) => item?.codes === 'All');
    let isAll = Boolean(value?.codes === 'All' && value?.name === 'All')
    const isEmpty = selectedClients.length === 0;


    if (!isExist && !isAll && !doesContainAll) {
      let temp = selectedClients.concat(value)
      setSelectedClients(temp)
    } else if (isExist) {
      const x = selectedClients.filter((item) => item !== value);
      setSelectedClients(x)
    } else if (isEmpty && isAll) {
      let temp = selectedClients.concat(value)
      setSelectedClients(temp)
    } else if (!isExist && doesContainAll) {
      setSelectedClients(selectedClients)
    }

  };


  const handleDeleteClient = (value) => {
    const x = selectedClients.filter((item) => item !== value);
    setSelectedClients(x)
  }


  const isSelected = (option) => selectedClients?.indexOf(option) !== -1;

  const capitalizedClientName = (clientName) => {
    return clientName?.toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Pt', 'PT')
      .replace('Cv', 'CV')
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("title", value.title);
    formData.set("content", value.content);
    for (let i = 0; i < selectedClients.length; i++) {
      formData.append("clientCode", selectedClients[i]?.codes);
    }
    if (
      value.image !== null &&
      value.image !== undefined &&
      value.image !== ""
    ) {
      formData.set("image", value.image[0], value.image[0].name);
    }
    formData.set("isPublish", true);

    if (value.title === "" || value.content === "" || value.clientCode === "") {
      setAlert(true);
    } else {
      dispatch(addInformation(token, formData));
      setModalSuccess(true);
    }
  };

  const handleClickCancel = (e) => {
    e.preventDefault();
    history.push("/superadmin/information");
  };

  const saveToDraft = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("title", value.title);
    formData.set("content", value.content);
    for (let i = 0; i < selectedClients.length; i++) {
      formData.append("clientCode", selectedClients[i]?.codes);
    }
    if (
      value.image !== null &&
      value.image !== undefined &&
      value?.image !== ""
    ) {
      formData.set("image", value.image[0], value.image[0].name);
    }
    formData.set("isPublish", false);

    if (value.title === "" || value.content === "" || value.clientCode === []) {
      setAlert(true);
    } else {
      dispatch(addInformation(token, formData));
      setModalDraft(true);
    }
  }


  useEffect(() => () => {
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response)
      } catch (error) {
        console.log(error)
      }
    }
    )()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    (async () => {
      try {
        const { response } = await fetchClientCode(token);
        setDataClientCode(response);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  return (
    <div className={Styles.Container}>
      <div className={Styles.Wrapper}>
        <form onSubmit={handleSubmit}>
          <div className={Styles.WrapperBox}>
            {alert && (
              <Alert sx={{ marginBottom: 3 }} severity="warning">
                Client, Title, and Content are mandatories!
              </Alert>
            )}
            <div style={{ display: "flex", gap: 10 }}>
              <div className={Styles.ItemBox}>
                <span>To</span>
                <FormControl size="small" fullWidth>
                  <Autocomplete
                    disablePortal
                    value={null}
                    blurOnSelect={true}
                    disableCloseOnSelect
                    options={fixedClients}
                    getOptionLabel={(option) => option?.name}
                    sx={{ width: '100%' }}
                    onChange={(e, value) =>
                      value === null
                        ? handleSelectedClient(e, null)
                        : handleSelectedClient(e, value)
                    }
                    renderOption={(props, option) => {
                      const isItemSelected = isSelected(option)
                      return (
                        <li {...props} onClick={(e) => e.preventDefault()} key={option?.codes} style={{ borderBottom: '1px solid #c2c2c2', padding: '12px' }}>
                          <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%'
                          }}>
                            <span className={Styles.ClientOption}>{capitalizedClientName(option?.name)}</span>
                            <Checkbox
                              onClick={(e) => handleSelectedClient(e, option)}
                              sx={{margin:0, padding:0}}
                              checked={isItemSelected}
                              icon={<RadioButtonUnchecked sx={{ color: '#c2c2c2' }} />}
                              checkedIcon={<RadioButtonChecked sx={{ color: '#1571DE' }} />} />
                          </div>
                        </li>

                      );
                    }}
                    renderInput={(params) => <MuiTextField {...params} size="small" sx={{ background: 'var(--main-bg)' }} placeholder="Select company" />}
                  />
                </FormControl>
              </div>
              <div className={Styles.ItemBox}>
                <span className={Styles.ItemBoxSpan}>Title</span>
                <input
                  className={Styles.ItemBoxInput}
                  type="text"
                  name="title"
                  onChange={handleEditData}
                  placeholder="Type title here"
                />
              </div>
            </div>

            {selectedClients?.length > 0 && (
              <div className={Styles.ClientsChipContainer}>
                {selectedClients.map((client, i) => (
                  <div key={i} className={Styles.ClientsChip}>
                    <span>
                      {capitalizedClientName(client?.name)}
                    </span>
                    <Cancel className={Styles.CancelIcon} onClick={() => handleDeleteClient(client)} />
                  </div>
                ))}

              </div>
            )}

            <div className={Styles.ItemBoxContent}>
              <span>Content</span>
              <textarea
                type="text"
                name="content"
                onChange={handleEditData}
                placeholder="Description of content"
              />
            </div>
            <div className={Styles.ContainerUploadImage}>
              <div {...getRootProps()} className={Styles.WrapperUploadImage}>
                <input {...getInputProps()} type="file" ref={inputFileRef} name="image" onChange={handleEditData} hidden />
                {preview ? (
                  <div
                    style={{
                      width: "100%",
                      margin: 0,
                      padding: 0,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: 30,
                    }}
                  >
                    <img
                      src={preview}
                      style={{
                        width: "245px",
                        height: "158px",
                        objectFit: "cover",
                        borderRadius: 8,
                      }}
                      alt={preview}
                      onClick={() => inputFileRef.current.click()}
                    />
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 20,
                          fontSize: "14px",
                        }}
                      >
                        <span>{previewName}</span>
                        <Button
                          type="button"
                          style={{
                            border: "1px solid #1571DE",
                            color: "#1571DE",
                            width: 120,
                            height: 40,
                            padding: 10,
                            borderRadius: 8,
                            fontSize: "14px",
                          }}
                          onClick={() => inputFileRef.current.click()}
                        >
                          Change File
                        </Button>
                      </span>
                      <img src={DELETE_ICON} onClick={handleDeleteImage} alt="delete" style={{ width: '18px', height: '20px', cursor: 'pointer' }} />

                    </div>
                  </div>
                ) : (
                  <div className={Styles.BoxInputImage}>
                    <span>Drag & Drop File Here</span>
                    <div className={Styles.BoxInputFile}>
                      <span>File must JPG or PNG</span>
                    </div>
                    <div className={Styles.BoxInputOr}>
                      <span> OR </span>
                    </div>
                    <div className={Styles.ContainerInputImage}>{ }</div>
                    <div className={Styles.BoxInputButton}>
                      <Button onClick={() => inputFileRef.current.click()}>
                        <span>Browse File</span>
                        <PublishIcon />
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={Styles.ButtonHelper}>
          <Button
              type="button"
              style={{
                border: "1px solid #1571DE",
                color: "#1571DE",
                width: 100,
                height: 40,
                padding: 10,
                borderRadius: 8,
              }}
              onClick={handleClickCancel}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              style={{
                background: "#1571DE",
                color: "white",
                width: 100,
                height: 40,
                padding: 10,
                borderRadius: 8,
              }}
            >
              Publish
            </Button>
            <span onClick={(e) => saveToDraft(e)}>Save To Draft</span>
          </div>
        </form>
        {modalSuccess && (
          <ModalPublishedInfo
            setModalSuccess={setModalSuccess}
            history={history}
            title="Success Published"
            text="you have send one information to the client"
          />
        )}
        {modalDraft && (
          <ModalPublishedInfo
            setModalSuccess={setModalDraft}
            history={history}
            title="Saved to Draft"
            text="you have saved one information to draft"
          />
        )}
      </div>
      <div>

      </div>
      <div></div>
    </div>
  );
}
