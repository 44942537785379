import * as types from '../../type'

const initialstate = {
  allInformation: [],
  informationById: {},
  updatedInformation: {},
  loadingDataInformation: false,
  errorMessageInformation: {}
};

const informationReducer = (state = initialstate, action) => {
  switch (action.type) {
    case types.FETCH_ALL_INFORMATION_BEGIN: {
      return {
        ...state,
        loadingDataInformation: true
      };
    }
    case types.FETCH_ALL_INFORMATION_SUCCESS: {
      return {
        ...state,
        loadingDataInformation: false,
        allInformation: action.payload,
      };
    } case types.FETCH_ALL_INFORMATION_ERROR: {
      return {
        ...state,
        loadingDataInformation: false,
        errorMessageInformation: action.payload
      };
    }
    case types.FETCH_ALL_INFORMATION_BY_ID: {
      return {
        ...state,
        informationById: action.payload,
      };
    }
    case types.UPDATE_INFORMATION: {
      return {
        ...state,
        updatedInformation: action.payload,
      };
    }
    default:
      return state;
  }
};

export default informationReducer;
