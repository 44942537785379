import axios from "axios";
import {API} from "../../api";
import * as types from '../../type'
//import { setSnackbar } from "./snackbarActions";

// ALL INFORMATION
export const fetchAllInformation = (token) => {
  return (dispatch) => {
    dispatch({ type: types.FETCH_ALL_INFORMATION_BEGIN })
    axios
      .get(`${API}/information`, {

        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_INFORMATION_SUCCESS,
          payload: res.data.response.informationUpdates,
        });
      })
      .catch((err) => {
        dispatch({ types: types.FETCH_ALL_INFORMATION_ERROR, payload: err.response })
        console.log(err);
      });
  };
};

//INFORMATION BY ID
export const fetchInformationById = (token, id) => {
  // console.log(token)
  return (dispatch) => {
    axios
      .get(`${API}/information/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.FETCH_ALL_INFORMATION_BY_ID,
          payload: res.data.response,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};


// ADD INFORMATION
export const addInformation = (token, body) => {
  return (dispatch) => {
    axios
      .post(`${API}/information`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.ADD_INFORMATION,
          payload: res.data.response,
        });
      })
      .then(() => {
        dispatch(fetchAllInformation(token))
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  };
};

// UPDATE INFORMATION
export const updateInformationById = (token, id, body) => {
  // console.log(token)
  return (dispatch) => {
    axios
      .patch(`${API}/information/${id}`, body, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.UPDATE_INFORMATION,
          payload: res.data.response,
        });
      })
      .then(() => {
        dispatch(fetchInformationById(token, id))
        return Promise.resolve();
      })
      .catch((err) => {
        console.log(err);
        return Promise.reject(err);
      });
  };
};

//DELETE INFORMATION

export const deleteInformation = (token, id) => {
  return (dispatch) => {
    axios
      .delete(`${API}/information/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((res) => {
        dispatch({
          type: types.DELETE_INFORMATION,
          payload: res.data.response,
        });
      })
      // .then(() => {
      //   dispatch(
      //     setSnackbar({
      //       snackbarOpen: true,
      //       snackbarType: "success",
      //       snackbarMessage: "Information deleted successfully",
      //     })
      //   );
      // })
      .then(() => {
        dispatch(fetchAllInformation(token));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// export const deleteInformation = (token, id) => {
//   return (dispatch) => {
//     axios
//       .delete(`${API}/notification/deleteNotif/${id}`, {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       })
//       .then((res) => {
//         dispatch({
//           type: DELETE_INFORMATION,
//           payload: res.data.data,
//         });
//         // return Promise.resolve(); 
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };
// };
